.card {
	max-width: 650px;
	margin: auto;
	text-align: center;
	overflow: hidden;
}

.card-header {
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
}

.card-header .page {
	font-size: 1.3em;
	color: #fff;
}

.card-header button {
	background: none;
	border: none;
	color: white;
	cursor: pointer;
	display: grid;
	place-items: center;
	height: 30px;
	width: 30px;
}

.card-header button:disabled,
.card-buttons button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.card-body {
	display: flex;
	flex-direction: column;
}

.card-title {
	font-size: 1.7em;
	font-weight: 500;
	text-align: left;
	width: fit-content;
	color: #fff;
}

.card-choices {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	flex-wrap: wrap;
}

.card-choice {
	width: fit-content;
	padding: 10px 30px;
	font-size: 1.3em;
	text-align: start;
	box-sizing: border-box;
	border: 1px solid white;
	color: white;
	border-radius: 50px;
	cursor: pointer;
}

.card-choice.selected {
	background-color: white;
	color: black;
	transition: background-color 0.4s;
}

.card-choice.selected span {
	background: linear-gradient(194.35deg, #e61366 -21.99%, #6f0b51 116.57%);
	background-clip: text;
	color: transparent;
}

.card-choice:not(.selected):hover {
	background-color: #ffffff54;
}

.result {
	padding: 15px 20px;
	font-size: 1.4rem;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.result img {
	width: 100%;
	height: auto;
}

@media screen and (max-width: 600px) {
	.card-choice {
		width: fit-content;
		padding: 8px 25px;
	}
	.card-choices .card-choice span {
		font-size: 1.1rem;
	}
}

.card-buttons {
	margin-top: 30px;
	display: grid;
	place-items: center;
	width: 100%;
}

.card-buttons button {
	background: white;
	border: none;
	color: black;
	cursor: pointer;
	display: grid;
	place-items: center;
	width: fit-content;
	border-radius: 40px;
	padding: 8px 30px;
	font-size: 1.5rem;
	font-family: "Raleway", sans-serif;
	font-weight: 400;
}

.result span {
	color: white;
	font-size: 2rem;
}
